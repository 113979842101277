import { checkPermission, bindHandler, checkRole, checkScope } from "./logic";
import { passportKey, passportRoleKey, passportScopeKey } from "./index";
import type { Options } from "./index";
import type { App } from "vue";

export default {
  install: (app: App<Element>, options: Options) => {
    const { getUserPermissions, getUserRoles, getUserScopes } = options;
    app.directive("auth", { created: bindHandler(getUserPermissions) });
    app.config.globalProperties.$passport = checkPermission(getUserPermissions);
    app.provide(passportKey, checkPermission(getUserPermissions));
    app.provide("passport", checkPermission(getUserPermissions));
    if (getUserRoles) {
      app.provide(passportRoleKey, checkRole(getUserRoles));
    }
    if (getUserScopes) {
      app.provide(passportScopeKey, checkScope(getUserScopes));
    }

    globalThis.passport = checkPermission(getUserPermissions);
    if (getUserScopes) globalThis.passportScope = checkScope(getUserScopes);
  },
};
