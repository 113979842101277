import AreqTools, { type Areq } from "@letsgoi/areq";
import { OAUTH } from "@/common/helpers/api/paths";
import { useBroadcastChannel } from "@vueuse/core";
import { watch } from "vue";

const { create, POST } = AreqTools;
const { post: postNewRefreshToken, data: bcRefreshToken } = useBroadcastChannel(
  {
    name: import.meta.env.VITE_APP_UUID,
  }
);

watch(bcRefreshToken, () => {
  globalThis.$eventbus.sendToVeins({
    UUID: import.meta.env.VITE_APP_VEINS_UUID,
    type: "auth/refreshToken",
    params: { token: { ...(bcRefreshToken.value as Areq.Token) } },
  });
});

async function onRefreshToken(config: Areq.Config): Promise<Areq.Token> {
  const areqInit = config.this?.init(config);
  const token = await areqInit()
    .then(config.this?.setHeaders())
    .then(config.this?.setJsonHeader)
    .then(
      config.this?.setBody({
        grant_type: import.meta.env.VITE_APP_DNA_GRAND_TYPE_REFRESH,
        client_id: import.meta.env.VITE_APP_DNA_CLIENT_ID,
        client_secret: import.meta.env.VITE_APP_DNA_CLIENT_SECRET,
        refresh_token: config?.settings?.token?.refresh_token,
      })
    )
    .then(config.this?.setApiHost(import.meta.env.VITE_APP_DNA_API_HOST))
    // .then(config.this?.setFetchOptions(fetchOptions))
    .then(
      POST(
        `${OAUTH}?refresh_token=${config?.settings?.token?.refresh_token}`,
        false
      )
    )
    .then(config.this?.jsonData<Areq.Token>());

  postNewRefreshToken(token);
  return token;
}
const settings: Areq.Settings = {
  apiHost: import.meta.env.VITE_APP_SC_API_HOST,
  interceptors: {
    onRefreshToken,
  },
};

const areq = create(settings);

export default areq;
