export default {
  operation_info: "Operation Info",
  logistic_info: "Logistic Name",
  business_name: "Business Name",
  client_name: "Client",
  created_at: "Creation date",
  address_name: "Address",
  unspecified: "--",
  list: {
    title: "Operations",
    "label_card-reference": "Reference",
    "label_card-address": "Address",
    "label_card-client": "Client",
    "label_card-name": "Name",
    "button_download-selection": "Download Selection",
    "button_download-all": "Download Everything",
  },
  "module-name": "Operations",
  operations: "Operations",
  business: "Business Name",
  logistic_name: "Logistic Name",
  client: "Client",
  province: "Province",
  form: {
    information_title: "Operation Information",
    field_reference: "Reference",
    "field_reference_error-required": "Required field",
    field_address: "Address",
    "field_address_error-required": "Required field",
    "field_additional-address": "Additional Address",
    "field_additional-address_error-required": "Required field",
    field_routeable: "Routeable",
    button_create: "Create",
    button_save: "Save changes",
  },
};
