export default {
  "export-all-to-routes": "Export All to Plan Route",
  "export-selected-to-routes": "Export only selected to Plan Route",
  "export-all-to-linehauls": "Export All to Linehauls",
  "export-selected-to-linehauls": "Export only selected to Linehauls",
  "export-to-routes": " Export to Plan Route",
  "export-all": "Export All",
  "export-selected": "Export only selected",
  "export-to-netsuite": "Export to Netsuite",
  "export-to-linehaul": "Export to Linehaul",
  "subtitle-export-all-routes":
    "Maximum 2,000 orders will be exported to Last Mile",
  "subtitle-export-selected-routes":
    "Export selected orders to be planned on Last Mile",
  "subtitle-export-all-linehaul": "Export all with a maximum of 100 orders",
  "subtitle-export-selected-linehaul":
    "Export selected orders to a Linehaul project",
  "subtitle-export-selected-netsuite":
    "Export selected orders for billing on Netsuite",
  "choose-project-list": "Choose a project from the list",
  "no-projects-title": "There are no projects created to be selected",
  "no-projects-subtitle": "Start by creating a new project to work with",
  "no-linehauls-title": "There are no linehauls created to be selected",
  "no-linehauls-subtitle": "Start by creating a new linehaul to work with",
  "create-project": "Create new project",
  "title-new-project": "Create New Project",
  "project-name": "Project Name",
  "select-date-project": "Select a date for this new project",
  "check-failed-orders": "Check failed orders",
  "open-last-mile": "Open exported on Last Mile",
  "routes-summary_count":
    "{numOrdersOk} of {numOrders} orders were exported successfully",
  "routes-summary-title":
    "Start planning on Routing or in case of export errors, check the failed orders on a new browser tab.",
  "routes-summary-subtitle":
    "If some orders couldn’t be exported this usually occur when orders have been previously added to currently active projects.",
  "select-date-linehaul": "Select a date for the Linehaul",
  "linehauls-summary_count":
    "{numOrdersOk} orders exported to linehaul project",
  "linehauls-summary-title":
    "Remember you are still able to add more orders, or to delete them anytime.",
  "linehauls-summary-subtitle":
    "Go to Linehaul and click on “view Details” at table list to edit the project.",
  close: "Close",
  "go-linehaul": "Go to Linehaul",
};
