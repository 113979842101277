import {
  ClipboardIcon,
  ExclamationTriangleIcon,
  BriefcaseIcon,
  UserGroupIcon,
  IdentificationIcon,
  TruckIcon,
  MapIcon,
  SparklesIcon,
  BoltIcon,
  BellAlertIcon,
  FolderOpenIcon,
} from "@heroicons/vue/24/outline";
import EllipsisHorizontalIcon from "@heroicons/vue/24/outline/EllipsisHorizontalIcon";
import { EMPTY_STRING } from "@/common/helpers/constants";

// Crear una relacion entre reference y pathname
export const config: Array<SidebarParent> = [
  {
    id: crypto.randomUUID(),
    name: "Orders",
    icon: ClipboardIcon,
    current: true,
    reference: "orders",
    pathname: new URL("/orders/list", globalThis.location.origin),
    permissions: "list-orders || create-orders",
    scope: "spinal-cord",
    children: [
      {
        id: crypto.randomUUID(),
        name: "Overview",
        pathname: new URL("/orders/list", globalThis.location.origin),
        search: new URL(
          "?page[value]=1&viewPreset[value]=default",
          globalThis.location.origin
        ),
        reference: "overview",
        current: false,
      },
      {
        id: crypto.randomUUID(),
        name: "RAEE",
        pathname: new URL("/orders/list", globalThis.location.origin),
        search: new URL(
          "?page[value]=1&viewPreset[value]=raee",
          globalThis.location.origin
        ),
        reference: "raee",
        permissions: "view-raee-module",
        current: false,
      },
    ],
  },
  {
    id: crypto.randomUUID(),
    name: "Incidences",
    icon: ExclamationTriangleIcon,
    current: false,
    reference: "incidences",
    pathname: new URL("/incidences/list", globalThis.location.origin),
    permissions: "list-incidences",
    scope: "spinal-cord",
    children: [],
  },
  {
    id: crypto.randomUUID(),
    name: "Alerts",
    icon: BellAlertIcon,
    reference: "alerts",
    current: false,
    pathname: new URL("/alerts/list", globalThis.location.origin),
    permissions: EMPTY_STRING,
    scope: "cortex",
    children: [],
  },
  {
    id: crypto.randomUUID(),
    name: "Shortcuts",
    icon: BoltIcon,
    reference: "shortcuts",
    current: false,
    permissions: "list-shortcuts",
    children: [],
  },
  {
    id: crypto.randomUUID(),
    name: "Clients",
    icon: FolderOpenIcon,
    reference: "clients",
    current: false,
    pathname: new URL("/clients/list", globalThis.location.origin),
    search: new URL(
      "?page[value]=1&viewPreset[value]=default",
      globalThis.location.origin
    ),
    permissions: "list-clients || list-deals",
    scope: "spinal-cord",
    children: [
      {
        id: crypto.randomUUID(),
        name: "Active Clients",
        pathname: new URL("/clients/list", globalThis.location.origin),
        search: new URL(
          "?page[value]=1&viewPreset[value]=default",
          globalThis.location.origin
        ),
        reference: "overview",
        current: false,
      },
      {
        id: crypto.randomUUID(),
        name: "Deals",
        pathname: new URL("/clients/deals/list", globalThis.location.origin),
        search: new URL(
          "?page[value]=1&viewPreset[value]=default",
          globalThis.location.origin
        ),
        reference: "deals",
        permissions: "list-deals",
        current: false,
      },
    ],
  },
  {
    id: crypto.randomUUID(),
    name: "Operations",
    icon: BriefcaseIcon,
    reference: "operations",
    current: false,
    pathname: new URL("/operations/list", globalThis.location.origin),
    search: new URL(
      "?page[value]=1&viewPreset[value]=default",
      globalThis.location.origin
    ),
    permissions: "list-operations",
    scope: "spinal-cord",
    children: [],
  },
  {
    id: crypto.randomUUID(),
    name: "Teams",
    icon: UserGroupIcon,
    reference: "teams",
    current: false,
    pathname: new URL("/teams/list", globalThis.location.origin),
    search: new URL(
      "?page[value]=1&viewPreset[value]=default",
      globalThis.location.origin
    ),
    permissions: "list-teams",
    scope: "spinal-cord",
    children: [],
  },
  {
    id: crypto.randomUUID(),
    name: "Providers",
    icon: IdentificationIcon,
    reference: "providers",
    current: false,
    pathname: new URL("/providers/list", globalThis.location.origin),
    search: new URL(
      "?page[value]=1&viewPreset[value]=default",
      globalThis.location.origin
    ),
    permissions: "create-providers && list-providers && view-providers",
    scope: "spinal-cord",
    children: [],
  },
  {
    id: crypto.randomUUID(),
    name: "Last Mile",
    icon: TruckIcon,
    current: false,
    reference: "last-mile",
    children: [],
    permissions: "list-projects && list-routes",
    scope: "spinal-cord",
    pathname: new URL("/routes/tracker", globalThis.location.origin),
  },
  {
    id: crypto.randomUUID(),
    name: "Linehauls",
    icon: MapIcon,
    current: false,
    reference: "linehauls",
    pathname: new URL("/linehauls/list", globalThis.location.origin),
    permissions: "list-linehauls || create-linehauls",
    scope: "spinal-cord",
    children: [],
  },
  {
    id: crypto.randomUUID(),
    name: "Dedicated",
    icon: SparklesIcon,
    reference: "dedicated-services",
    pathname: new URL("/dedicated-services/list", globalThis.location.origin),
    current: false,
    permissions: "list-dedicated_services || create-dedicated_services",
    scope: "spinal-cord",
    children: [
      {
        id: crypto.randomUUID(),
        name: "Overview",
        reference: "overview",
        pathname: new URL(
          "/dedicated-services/list",
          globalThis.location.origin
        ),
        search: new URL(
          "?page[value]=1&viewPreset[value]=default",
          globalThis.location.origin
        ),
        current: false,
      },
      {
        id: crypto.randomUUID(),
        name: "Calendar",
        pathname: new URL(
          "/dedicated-services/calendar",
          globalThis.location.origin
        ),
        current: false,
      },
    ],
  },
  {
    id: crypto.randomUUID(),
    name: "More Options",
    icon: EllipsisHorizontalIcon,
    current: false,
    reference: "more-options",
    children: [
      {
        id: crypto.randomUUID(),
        name: "News",
        pathname: new URL("https://headwayapp.co/goi-group-news"),
        current: false,
        role: "goi",
      },
      {
        id: crypto.randomUUID(),
        name: "Logout",
        reference: "logout",
        pathname: new URL("/auth/login", globalThis.location.origin),
        current: false,
      },
    ],
  },
];
